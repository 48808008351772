<template>
  <div>
    <v-container fluid>
      <v-card height="400" color="transparent" flat>
        <v-card-title class="text-h2"> Erreur 404 </v-card-title>
        <v-card-subtitle class="text-h6"> Page non trouvée </v-card-subtitle>
        <v-card-text>
          Ce lien n'existe pas ou plus. Il a pu être déplacé ou supprimé. Vous
          pouvez retourner à l'accueil en cliquant sur le bouton ci-dessous.
        </v-card-text>
        <v-btn outlined to="/" class="mt-1"> Retour Accueil </v-btn>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>